type QueryParams = any
export const toQueryString = (queryParams: QueryParams): string  => {
  const urlSearchParams = new URLSearchParams();

  for (const key in queryParams) {
    const value = queryParams[key as keyof QueryParams];

    if (Array.isArray(value) && value.length > 0) {
      value.forEach(v => {
        urlSearchParams.append(`q[${key}][]`, String(v));
      });
    } else if (value !== undefined && value !== null) {
      urlSearchParams.append(`q[${key}]`, String(value));
    }
  }
  return urlSearchParams.toString();
}
