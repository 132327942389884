<script lang="ts" setup>
import { ref, computed, watch } from 'vue'
import { toQueryString } from '../../../utils/to_query_string'
import type { LocaleCode } from '../../../utils/i18n'
import { useI18n } from 'vue-i18n'
import _ from 'lodash'
import { get } from '@rails/request.js'
import { openSignInForm } from '../../../utils/open_sign_in_form'

const props = defineProps<{
  locale: LocaleCode,
  user_signed_in: boolean,
  data: {
    recommendation_enabled: boolean,
    using_software_format_ids: number[],
    owned_software_format_ids: number[],
    paths: {
      new_account_game_registration_path: string,
    }
  }
}>()
const { t } = useI18n({ locale: props.locale })

const searchParams = ref({
  freeword: '',
  priority_acclaimed: 0,
  priority_published: 20,
  priority_recommended: 0,
  only_using_software_formats: false,
  only_owned_software_formats: false
})
if (props.data.recommendation_enabled) {
  searchParams.value.priority_recommended = 20
}
const showModal = ref(false)
watch(searchParams, () => {
  showModal.value = searchParams.value.freeword.length >= 2
}, { deep: true })
const closeModal = () => {
  showModal.value = false
}

type Game = {
  id: number
  title: string
  release_year: number | null
  show_path: string
  genre: string | null
  developer: string | null
  thumbnail_url: string
}
const games = ref<Game[]>([])

const queryParams = computed(() => {
  const params: any = {
    software_format_ids: [],
    ..._.pick(searchParams.value, [
      'freeword', 'priority_acclaimed',
      'priority_published', 'priority_recommended'
    ])
  }
  if (searchParams.value.only_using_software_formats) {
    params.software_format_ids = props.data.using_software_format_ids
  }
  if (searchParams.value.only_owned_software_formats) {
    params.software_format_ids = _.union(
      params.software_format_ids,
      props.data.owned_software_format_ids
    )
  }
  return params
})

const searchPath = computed(() => {
  return `/games?${toQueryString(queryParams.value)}`
})

const searching = ref(false)

const searchGames = async() => {
  searching.value = true
  const response = await get(
    `/games/search_in_header?${toQueryString(queryParams.value)}`,
    { responseKind: 'json' }
  )
  if (response.ok) {
    const json = await response.json
    games.value = json.games
  }
  searching.value = false
}
const debouncedSearch = _.debounce(searchGames, 500)
watch(queryParams, () => {
  if (showModal.value) {
    debouncedSearch()
  }
})

const moveToGamesIndex = () => {
  window.location.href = searchPath.value
}

</script>

<template lang="pug">
form.navs__header-form(class="disabled" data-header-content="search")
  div.navs__header-form-field(data-header-content="form" role="search")
    input.navs__header-form-input.uk-input(
      :placeholder="t('form.placeholder.freeword')"
      v-model="searchParams.freeword"
      @keypress.prevent.enter="moveToGamesIndex"
      id="search_in_header_freeword"
    )
    button.navs__header-form-submit(
      @click.prevent="moveToGamesIndex"
    )
  div.navs__header-form-window(v-show="showModal")
    div.navs__header-form-close(@click="closeModal")
      fa(:icon="['fas', 'times']")

    ul.navs__header-form-priority

      li.navs__header-form-handle(data-header-slider-level="")
        div.navs__header-form-handle-text
          fa(:icon="['fas', 'heart']" class="font-awesome-icon prefer")
          | {{ t('form.priority.recommended') }}
        div.navs__header-form-handle-scale
          div.navs__header-form-handle-label(class="low")
            | {{ t('form.priority.no_matter') }}
          input.navs__header-form-handle-slider(
            id="search_in_header_priority_recommended"
            name="search_in_header_priority_recommended"
            min="0" max="100" type="range"
            v-model.number="searchParams.priority_recommended"
            :disabled="!props.user_signed_in"
          )
          div.navs__header-form-handle-label(class="high")
            | {{ t('form.priority.important') }}
        div.navs__header-form-handle-affect
          | {{ searchParams.priority_recommended }}%

      li.navs__header-form-handle(data-header-slider-level="")
        div.navs__header-form-handle-text
          fa(:icon="['fas', 'calendar']" class="font-awesome-icon rating")
          | {{ t('form.priority.published') }}
        div.navs__header-form-handle-scale
          div.navs__header-form-handle-label(class="low")
            | {{ t('form.priority.no_matter') }}
          input.navs__header-form-handle-slider(
            id="search_in_header_priority_published"
            name="search_in_header_priority_published"
            min="0" max="100" type="range"
            v-model.number="searchParams.priority_published"
          )
          div.navs__header-form-handle-label(class="high")
            | {{ t('form.priority.important') }}
        div.navs__header-form-handle-affect
          | {{ searchParams.priority_published }}%

      li.navs__header-form-handle(data-header-slider-level="")
        div.navs__header-form-handle-text
          fa(:icon="['fas', 'star']" class="font-awesome-icon rating")
          | {{ t('form.priority.acclaimed') }}
        div.navs__header-form-handle-scale
          div.navs__header-form-handle-label(class="low")
            | {{ t('form.priority.no_matter') }}
          input.navs__header-form-handle-slider(
            id="search_in_header_priority_acclaimed"
            name="search_in_header_priority_acclaimed"
            min="0" max="100" type="range"
            v-model.number="searchParams.priority_acclaimed"
          )
          div.navs__header-form-handle-label(class="high")
            | {{ t('form.priority.important') }}
        div.navs__header-form-handle-affect
          | {{ searchParams.priority_acclaimed }}%

    div.navs__header-form-result
      div.navs__header-form-empty(v-if="!searching && games.length === 0")
        p.navs__header-form-empty-text
          | {{ t('no_results') }}

        a.navs__header-form-empty-register(
          v-if="props.user_signed_in"
          :href="props.data.paths.new_account_game_registration_path"
        )
          | {{ t('register_game') }}
          
        div.navs__header-form-empty-register(
          v-else
          @click="openSignInForm"
        )
          | {{ t('register_game') }}
          | {{ t('need_to_sign_in') }}

      div.navs__header-form-games(v-else)
        a(v-for="(game, i) in games" :href="game.show_path" :key="game.id")
          div.navs__header-form-number {{ i + 1 }}.
          div.navs__header-form-overview
            span {{ game.title }}
            var {{ _.compact([game.genre, game.release_year, game.developer]).join('/') }}
          div.navs__header-form-image
            img(
              :src="game.thumbnail_url"
              :alt="game.title"
              width="61" height="30"
              loading="lazy"
            )

      div.navs__header-form-loading(:class="{ disabled: !searching }")
        div.parts__loading(data-loading-animation="pacman")
          | <div></div><div></div><div></div><div></div><div></div>

    ul.navs__header-form-condition
      li
        input(
          id="search_in_header_only_using_software_formats"
          class="uk-checkbox uk-checkbox-small"
          type="checkbox"
          v-model="searchParams.only_using_software_formats"
          :disabled="props.data.using_software_format_ids.length === 0"
        )
        label(for="search_in_header_only_using_software_formats")
          | {{ t('form.using_software_formats') }}
      li
        input(
          id="search_in_header_only_owned_software_formats"
          class="uk-checkbox uk-checkbox-small"
          type="checkbox"
          v-model="searchParams.only_owned_software_formats"
          :disabled="props.data.owned_software_format_ids.length === 0"
        )
        label(for="search_in_header_only_owned_software_formats")
          | {{ t('form.owned_software_formats') }}
    div.navs__header-form-more
      a(:href="searchPath")
        | {{ t('see_more') }}
</template>

<i18n lang="json">
{
  "ja": {
    "form": {
      "placeholder": {
        "freeword": "例）PC FPS オープンワールド"
      },
      "priority": {
        "acclaimed": "評価の高さ",
        "published": "新しさ",
        "recommended": "自分の好み",
        "no_matter": "不問",
        "important": "重要"
      },
      "using_software_formats": "使ってるハードの作品",
      "owned_software_formats": "持ってるハードの作品"
    },
    "no_results": "該当するゲームが見つかりません",
    "register_game": "ゲームの登録にご協力ください",
    "need_to_sign_in": "（ログインが必要です）",
    "see_more": "もっと見る"
  }
}
</i18n>
