import App from '@/components/header/search/App.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTimes, faStar, faCalendar, faHeart } from '@fortawesome/free-solid-svg-icons'
import { createMyApp } from '@/utils/create_my_app'

document.addEventListener('turbo:load', () => {
  const id = 'search_in_header';
  const rawProps = document.getElementById(id)?.getAttribute('data');
  library.add(faTimes, faStar, faCalendar, faHeart);
  if(rawProps) {
    const props = JSON.parse(rawProps);
    const app = createMyApp(App, props);
    app.component('fa', FontAwesomeIcon).
      mount(`#${id}`);
  }
})
